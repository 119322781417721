import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76020ce0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrap" }
const _hoisted_2 = {
  key: 1,
  class: "title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabsGroup = _resolveComponent("TabsGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "toggle-backspace" }, {
      default: _withCtx(() => [
        (_ctx.drawerStore.opened)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "backspace",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args)))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "toggle-drawer" }, {
      default: _withCtx(() => [
        (_ctx.drawerStore.opened)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "drawer",
              ref: "PopUpRef",
              style: _normalizeStyle({ maxHeight: _ctx.maxHeight })
            }, [
              _createElementVNode("div", _hoisted_1, [
                (!_ctx.isMobileScreen && !_ctx.isChangeLayout)
                  ? (_openBlock(), _createBlock(_component_TabsGroup, {
                      key: 0,
                      class: "header",
                      activeTab: _ctx.activeTab,
                      "onUpdate:activeTab": [
                        _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeTab) = $event)),
                        _ctx.changeSection
                      ],
                      tabs: _ctx.tabs
                    }, null, 8, ["activeTab", "onUpdate:activeTab", "tabs"]))
                  : _createCommentVNode("", true),
                (_ctx.title)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
                  : _createCommentVNode("", true),
                (!_ctx.isMobileScreen)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "close",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeDrawer && _ctx.closeDrawer(...args)))
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_Transition, {
                  name: "slide-tab",
                  duration: 350
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ]),
                  _: 3
                })
              ])
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 64))
}