import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createBlock(_component_Notification, _mergeProps({
    class: "notificator",
    ref: "popup"
  }, _ctx.notificatorArg, {
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeMeetingStatus({status: _ctx.statusType.none})))
  }), null, 16))
}