
  import { Notification } from '@voximplant/spaceui';
  import { computed, defineComponent, ref, watch } from 'vue';
  import { changeMeetingStatus, $meetingStatus, statusType } from '@/store/meeting';
  import { useStore } from 'effector-vue/composition.cjs';

  export default defineComponent({
    name: 'PopUpMeetingStatus',
    components: {
      Notification,
    },
    setup() {
      const closeTimeout = 5000;
      const popup = ref(null);
      watch(popup, () => {
        // close Notification 5 second after component rendering
        return setTimeout(
          () => changeMeetingStatus({ status: statusType.none, reason: '' }),
          closeTimeout
        );
      });

      const meetingStatus = useStore($meetingStatus);
      const notificatorArg = computed(() => ({
        mode: 'error',
        title: meetingStatus.value.reason,
      }));
      return {
        notificatorArg,
        changeMeetingStatus,
        statusType,
        popup,
      };
    },
  });
