
  import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
  import { Button, TabsGroup } from '@voximplant/spaceui';
  import { closeDrawer, $drawer, openDrawer } from '@/store/drawer';
  import { useStore } from 'effector-vue/composition';
  import { $usersInCall } from '@/store/users';
  import { useI18n } from 'vue-i18n';
  import { useIsMobileScreen } from '@/hooks/isMobile';
  import { useRoute } from 'vue-router';
  import { $newMessagesCount } from '@/store/chat';

  const __default__ = defineComponent({
    name: 'Drawer',
    props: {
      title: {
        type: String,
      },
      maxHeight: {
        type: String,
        default: '100%',
      },
    },
    components: {
      Button,
      TabsGroup,
    },
    setup() {
      const { t } = useI18n();
      const drawerStore = useStore($drawer);
      const { isMobileScreen } = useIsMobileScreen();
      const route = useRoute();
      // show changeLayout without chat and users tabs
      const isChangeLayout = computed(
        () => drawerStore.value.section === 'changeLayout' && route.name !== 'VideoConference'
      );
      const usersInCall = useStore($usersInCall);
      const usersCount = computed(() => usersInCall.value.length);
      const newMessagesCount = useStore($newMessagesCount);
      const tabs = computed(() => [
        // workaround for correct animation of tab sliding
        ...(isMobileScreen.value ? [{ type: 'generalSettings' as const }] : []),
        {
          title: t('buttons.chat'),
          type: 'chat' as const,
          badge: newMessagesCount.value,
        },
        { title: t('buttons.users'), type: 'contactList' as const, badge: usersCount },
        { title: t('buttons.changeLayout'), type: 'changeLayout' as const },
      ]);
      const activeTab = ref(0);
      const activeSection = computed(() => tabs.value[activeTab.value].type);

      watch(
        () => drawerStore.value.section,
        (section) => {
          activeTab.value = tabs.value.findIndex(({ type }) => type === section);
        }
      );

      const changeSection = () => {
        if (activeSection.value !== drawerStore.value.section) {
          openDrawer({ section: activeSection.value });
        }
      };

      const PopUpRef = ref<HTMLElement | null>(null);

      const onKeyDownEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          closeDrawer();
        }
      };

      onMounted(() => {
        document.addEventListener('keydown', onKeyDownEsc);
      });
      onBeforeUnmount(() => {
        document.removeEventListener('keydown', onKeyDownEsc);
      });

      const slideDirection = ref('forward');
      watch(activeTab, (newTab, oldTab) => {
        if (newTab === oldTab) return;
        slideDirection.value = newTab > oldTab ? 'forward' : 'back';
      });
      const slideTranslateValues = computed(() => ({
        from: slideDirection.value === 'forward' ? '110%' : '-110%',
        to: slideDirection.value === 'forward' ? '-110%' : '110%',
      }));

      return {
        tabs,
        drawerStore,
        closeDrawer,
        isMobileScreen,
        PopUpRef,
        activeTab,
        changeSection,
        isChangeLayout,
        slideTranslateValues,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "03e6df44": (_ctx.slideTranslateValues.from),
  "2a17f495": (_ctx.slideTranslateValues.to)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__